import React, { useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { StateContext } from '../../StateProvider';
import './Profile.css';
import Switch from "react-switch";
import Popup from '../../Utilities/components/Popup';

import membershipIcon from '../../../assets/Dashboard/Profile/membershipIcon.png';
import guidesIcon from '../../../assets/Dashboard/Profile/guidesIcon.png';
import friendsIcon from '../../../assets/Dashboard/Profile/friendsIcon.png';

import personPlaceholder from '../../../assets/Dashboard/Profile/personPlaceholder.png';
import addIcon from '../../../assets/Dashboard/Profile/addIcon.png';
import dogPlaceholder from '../../../assets/Dashboard/Profile/dogPlaceholder.png';
import catPlaceholder from '../../../assets/Dashboard/Profile/catPlaceholder.png';

import familyBackground from './../../../assets/Dashboard/Profile/profileBackground.png';
import alternateBackgroundLessThanFour from './../../../assets/Dashboard/Profile/alternateBackgroundLessThanFour.png';
import alternateBackgroundMoreThanSix from './../../../assets/Dashboard/Profile/alternateBackgroundMoreThanSix.png';

import walletIcon from '../../../assets/Landing/walletIcon.png';
import transactionsIcon from '../../../assets/Landing/transactionsIcon.png'

import editIcon from './../../../assets/Dashboard/Profile/editIcon.png';

import { signOutUser, auth }  from '../../Login/Firebase';


export default function Profile() {
  const navigate = useNavigate();
  const { 
    user,
    setLoggedIn,
    setLoading,
    handleFileChange,
    handleUpload
  } = useContext(StateContext);
  const [currentLocation, setCurrentLocation] = useState('Select Location');
  const [emailUpdate, setEmailUpdate] = useState(true);
  const [phoneUpdate, setPhoneUpdate] = useState(true);
  const [emailNotifications, setEmailNotifications] = useState(true);
  const [smsNotifications, setSmsNotifications] = useState(false);
  const [confirmationSent, setConfirmationSent] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupContent, setPopupContent] = useState('');
  const [confirmation, setConfirmation] = useState(null);
  const [newEmail, setNewEmail] = useState('');
  const [newPhone, setNewPhone] = useState('');
  const [remainingDaysCalculated, setRemainingDaysCalculated] = useState(0);

  useEffect(() => {
    // Here you could have logic to determine the user's current location,
    // perhaps using the browser's Geolocation API or a default value.
    // For example:
    // navigator.geolocation.getCurrentPosition(function(position) {
    //   setCurrentLocation(`Lat: ${position.coords.latitude}, Long: ${position.coords.longitude}`);
    // });

    // For this example, we'll just set a static value:
    setCurrentLocation('Los Angeles, USA');
  }, []);

  if (!user) {
    return <div>Loading...</div>;
  }
  
  // Before accessing user.info.phone or user.info.email
  const phoneNumber = user && user.info && user.info.phone ? user.info.phone : '';
  const email = user && user.info && user.info.email ? user.info.email : '';

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber || phoneNumber.length !== 10) return phoneNumber;
    const areaCode = phoneNumber.slice(0, 3);
    const middlePart = phoneNumber.slice(3, 6);
    const lastPart = phoneNumber.slice(6, 10);
    return `${areaCode}-${middlePart}-${lastPart}`;
  };

  const handleProfileClick = () => {
    navigate(`/profile/edit-profile`);
    window.scrollTo(0, 0);
  }

  const handleProfileCardClicked = (page) => {
    navigate(`/${page}`);
    window.scrollTo(0, 0);
  }

  const confirmPopupChange = (type) => {
    setConfirmation(type);
    if (type === 'email' || type === 'sms') {
      togglePopup('confirm');
    } else if (type === 'emailUpdate') {
      togglePopup('emailUpdate');
    } else if (type === 'phoneUpdate') {
      togglePopup('phoneUpdate');
    } else if (type === 'confirmationSent') {
      togglePopup('confirmationSent');
    }
  };
  
  const handleEmailNotificationsChange = () => {
    if (emailNotifications) {
      confirmPopupChange('email');
    } else {
      setEmailNotifications(true);
    }
  };

  const handleEmailChange = () => {
    // If emailNotifications is true, we want to confirm turning them off
    if (emailUpdate) {
      confirmPopupChange('emailUpdate');
    } else {
      setEmailUpdate(true);
    }
  };

  const handlePhoneChange = () => {
    // If emailNotifications is true, we want to confirm turning them off
    if (phoneUpdate) {
      confirmPopupChange('phoneUpdate');
    } else {
      setPhoneUpdate(true);
    }
  };

  const handleSmsNotificationsChange = () => {
    if (smsNotifications) {
      confirmPopupChange('sms');
    } else {
      setSmsNotifications(true);
    }
  };  

  const togglePopup = (content = '') => {
    setPopupContent(content);
    setIsPopupOpen(!isPopupOpen);
  };

  const handleConfirm = () => {
    if (confirmation === 'email') {
      setEmailNotifications(false);
    } else if (confirmation === 'sms') {
      setSmsNotifications(false);
    }
    closePopup();
    setConfirmation(null);
  };
  
  const handleCancel = () => {
    closePopup();
    setConfirmation(null);
  };
  
  const closePopup = () => {
    setIsPopupOpen(false);
    setPopupContent('');
  };  

  const handleUpdateEmail = () => {
    setPopupContent('confirmationSent');
    setConfirmationSent(true);
  };
  
  const handleUpdatePhone = () => {
    setPopupContent('confirmationSent');
    setConfirmationSent(true);
  };  

  const handleSignOut = async () => {
    setLoading(true);
    try {
        await signOutUser(auth);
        setLoggedIn(false);
        navigate('/');
        window.scrollTo(0, 0);
        console.log('App Sign-out Successful');
    } catch (error) {
        console.error('Error signing out: ', error);
    }
    closePopup(); // Close the popup whether or not the sign-out was successful
    setLoading(false);
  };  

  let profileBackground;
  if (user && user.familyMembers && user.familyMembers.length < 4) {
    profileBackground = `url(${alternateBackgroundLessThanFour})`;
  } else if (user && user.familyMembers && user.familyMembers.length > 6) {
    profileBackground = `url(${alternateBackgroundMoreThanSix})`;
  } else {
    profileBackground = `url(${familyBackground})`;
  }

  const profileBackgroundStyle = {
    backgroundImage: profileBackground,
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center top',
  };

  const placeholderImages = {
    Human: personPlaceholder,
    Dog: dogPlaceholder,
    Cat: catPlaceholder,
  };

  function getPlaceholderImage(type) {
    return placeholderImages[type] || placeholderImages.Human;
  }

  return (
    <div className="container" style={profileBackgroundStyle}>
      <div className="family-section">
        <div className="family-header">
          <div className="title-and-button-container">
            <h2 className='family-title' onClick={handleProfileClick}>Your Family</h2>
            <button className='family-edit-button' onClick={handleProfileClick}>Edit<img className='family-edit-image' src={editIcon} alt="Edit Profile" /></button>
          </div>
        </div>
        <div className="family-member-section">
          {user && user.familyMembers && user.familyMembers.map((member, index) => (
            <div key={index} className="family-member">
              <img 
                className='family-member-image' 
                src={member.image || getPlaceholderImage(member.type)} 
                alt={`${member.type} Placeholder`} 
                onClick={handleProfileClick}
              />
              <h3 className='family-member-name' onClick={handleProfileClick}>{member.name}</h3>
              <p className='family-member-type' onClick={handleProfileClick}>{member.type}</p>
            </div>
          ))}
          {user.familyMembers && (user.familyMembers.length < 2) && (
            <div className="family-member" onClick={() => navigate('/profile/edit-family-member/add-family-member')}>
              <img 
                className='family-member-image' 
                src={addIcon} 
                alt="Add New" 
              />
              <h3 className='family-member-name'>Add</h3>
              {/* <p className='family-member-type'>Profile</p> */}
            </div>
          )}
        </div>
      </div>

      <div className="section">
        <div className="profile-card-container">
          <div className="profile-card" onClick={() => handleProfileCardClicked('profile/membership')}>
              <img src={membershipIcon} alt="Membership Icon" />
              <h3>Membership</h3>
          </div>
          {/* <div className="profile-card" onClick={() => handleProfileCardClicked('blog')}> */}
          <div className="profile-card" onClick={() => handleProfileCardClicked('coming-soon')}>
              <img src={guidesIcon} alt="Guides Icon" />
              <h3>Guides</h3>
          </div>
          <div className="profile-card" onClick={() => handleProfileCardClicked('profile/friends')}>
              <img src={friendsIcon} alt="Friends Icon" />
              <h3>Friends</h3>
          </div>
          <div className="profile-card" onClick={() => handleProfileCardClicked('profile/wallet')}>
              <img src={walletIcon} alt="Wallet Icon" />
              <h3>Wallet</h3>
          </div>
          <div className="profile-card" onClick={() => handleProfileCardClicked('profile/wallet/payment-history')}>
              <img src={transactionsIcon} alt="Transactions Icon" />
              <h3>Transactions</h3>
          </div>
        </div>
      </div>

      <div className="section">
        {/* <h2 className='account-title'>Account Info</h2> */}
          <div className="account-info">
            <div className="info-settings-card">
                <h3 className='info-card-title'>Location</h3>
                <p className='info-location-card-info'>{currentLocation}</p>
            </div>
            <hr className='profile-breakline'/>
            <div className="info-settings-card">
                <h3 className='info-card-title'>Email Notifications</h3>
                <p className='info-card-info' onClick={handleEmailChange}>{email}</p>
                <Switch 
                  onChange={handleEmailNotificationsChange} 
                  checked={emailNotifications} 
                  className="react-switch"
                  onColor="#FFB516"
                  uncheckedIcon={false}
                  checkedIcon={false}
                  height={20} // Height of the background of the switch
                  width={40} // Width of the background of the switch
                />
            </div>
            <hr className='profile-breakline'/>
            <div className="info-settings-card">
                <h3 className='info-card-title'>SMS Notifications</h3>
                <p className='info-card-info' onClick={handlePhoneChange}>{formatPhoneNumber(phoneNumber)}</p>
                <Switch 
                  onChange={handleSmsNotificationsChange} 
                  checked={smsNotifications} 
                  className="react-switch"
                  onColor="#FFB516"
                  uncheckedIcon={false}
                  checkedIcon={false}
                  height={20} // Height of the background of the switch
                  width={40} // Width of the background of the switch
                />
            </div>
            <hr className='profile-breakline'/>
            <div className="info-signOut-card" onClick={() => togglePopup('signOut')}>
                <h3 className='info-signOut-title'>Sign Out</h3>
            </div>
            {/* <Link to="/profile/sign-out" className="link-no-underline">
              <div className="info-signOut-card">
                <h3 className='info-signOut-title'>Sign Out</h3>
              </div>
            </Link> */}
            <hr className='profile-breakline'/>

            <Popup isOpen={isPopupOpen} handleClose={closePopup}>
              {popupContent === 'confirm' && (
                <div className='profile-popup'>
                  <h2 className='profile-popup-header'>Unsubscribe?</h2>
                  <p className='profile-popup-message'>Opting out could result in missing important booking information from Pawsible.</p>
                  <div className="profile-popup-actions">
                    <button onClick={handleConfirm} className='profile-popup-yes-button'>YES</button>
                    <button onClick={handleCancel} className='profile-popup-no-button'>NO</button>
                  </div>
                </div>
              )}
              {popupContent === 'emailUpdate' && (
                <div className='profile-popup'>
                  <h2 className='profile-popup-header'>Update Email</h2>
                  <p className='profile-popup-message'>{email}</p>
                  <input
                    type="email"
                    placeholder="Enter new email"
                    value={newEmail}
                    onChange={(e) => setNewEmail(e.target.value)}
                  />
                  <div className="profile-popup-actions">
                    <button onClick={handleUpdateEmail} className='profile-popup-update-button-yes'>Confirm</button>
                    <button onClick={handleCancel} className='profile-popup-update-button-no'>Cancel</button>
                  </div>
                </div>
              )}
              {popupContent === 'phoneUpdate' && (
                <div className='profile-popup'>
                  <h2 className='profile-popup-header'>Update Number</h2>
                  <p className='profile-popup-message'>{phoneNumber}</p>
                  <input
                    type="tel"
                    placeholder="Enter new phone number"
                    value={newPhone}
                    onChange={(e) => setNewPhone(e.target.value)}
                  />
                  <div className="profile-popup-actions">
                    <button onClick={handleUpdatePhone} className='profile-popup-update-button-yes'>Confirm</button>
                    <button onClick={handleCancel} className='profile-popup-update-button-no'>Cancel</button>
                  </div>
                </div>
              )}
              {popupContent === 'confirmationSent' && (
                <div className='profile-popup'>
                  <h2 className='profile-popup-header'>Confirmation Sent!</h2>
                  <p className='profile-popup-message'>To finalize the update of your contact details, please <strong>confirm the verification</strong> sent to your Email/Phone.</p>
                  <p className='profile-popup-message'>We appreciate your prompt attention to this matter!</p>
                  <div className="profile-popup-actions-confirmation">
                    <button onClick={handleCancel} className='profile-popup-update-button-ok'>OK</button>
                    {/* <button onClick={handleCancel} className='profile-popup-update-button-no'>Cancel</button> */}
                  </div>
                </div>
              )}
              {popupContent === 'signOut' && (
                  <div className='profile-popup'>
                    <h2 className='profile-popup-header'>Confirm Sign Out</h2>
                    <p className='profile-popup-message'>Are you sure you want to sign out?</p>
                    <div className="profile-popup-actions">
                        <button onClick={handleSignOut} className='profile-popup-confirm-button'>Yes</button>
                        <button onClick={handleCancel} className='profile-popup-cancel-button'>No</button>
                    </div>
                  </div>
              )}
            </Popup>
          </div>
      </div>

    </div>
  );
}