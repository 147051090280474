import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment-timezone';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import Spinner from '../Utilities/components/Spinner';
import { fetchUserData, fetchAllServices } from '../Utilities/api';

export const StateContext = React.createContext();

const API_BASE_URL = `https://www.pawsible.app` || 'http://localhost:6060';
// const API_BASE_URL = 'http://localhost:6060';

const StateProvider = ({ children, loggedIn, setLoggedIn, role, setRole }) => {
    const navigate = useNavigate();
      
    // States
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const [cart , setCart] = useState([]);
    const [file, setFile] = useState(null);
    const [cartTotal, setCartTotal] = useState(0);
    const [cartOriginalTotal, setCartOrigialTotal] = useState(0);
    const [cartTotalDiscount, setCartTotalDiscount] = useState(0);
    const [showAddPetsModal, setShowAddPetsModal] = useState(false);
    const [serviceDetails, setServiceDetails] = useState({});
    const [paymentMethods, setPaymentMethods] = useState([]);

    useEffect(() => {
        const auth = getAuth();

        const getServiceDetails = async () => {
            try {
                const details = await fetchAllServices();
                console.log('Service details fetched from server');
                setServiceDetails(details);
            } catch (error) {
                console.error('Error fetching service details:', error);
            }
        };

        const syncUserData = async (userData) => {
            try {
                await axios.post(`${API_BASE_URL}/api/users/sync`, userData);
                console.log('User data synced with server');
                return true;
            } catch (error) {
                console.error('Failed to sync user data:', error);
                return false;
            }
        };

        const fetchData = async (uid) => {
            setLoading(true);
            try {
                const userData = await fetchUserData(uid);
                console.log('User data fetched from server');

                // Create a user object as a family member
                const userAsFamilyMember = {
                    name: userData.username ? userData.username.split(' ')[0] : 'Name',
                    type: 'Human',
                    image: userData.image || 'https://pawsible-s3.s3.us-west-1.amazonaws.com/user_files/defaultPerson.png', 
                };

                // Optionally sort the remaining family members if necessary, avoiding changing the user's position
                const sortedFamilyMembers = [userAsFamilyMember, ...userData.FamilyMembers.filter(member => member.deletedAt === null).sort((a, b) => {
                    if (a.type === 'Human' && b.type !== 'Human') {
                        return -1; // a comes first
                    } else if (a.type !== 'Human' && b.type === 'Human') {
                        return 1; // b comes first
                    } else {
                        return a.name.localeCompare(b.name); // Sorting by name alphabetically as a secondary criteria
                    }
                })];

                // Convert booking times to local time zone
                const localBookings = userData.Bookings.map(booking => ({
                    ...booking,
                    startTime: convertToUserTimeZone(booking.startTime),
                    endTime: convertToUserTimeZone(booking.endTime)
                }));

                const activePaymentMethods = userData.PaymentMethods.filter(method => method.deletedAt === null);
                setPaymentMethods(activePaymentMethods);

                const mappedUserData = {
                    id: userData.id,
                    uid: userData.uid,
                    username: userData.username,
                    email: userData.email,
                    phone: userData.phone,
                    password: userData.password,
                    createdAt: userData.createdat,
                    updatedAt: userData.updatedat,
                    wallet: userData.Wallet,
                    userFriends: userData.UserFriends,
                    cartItems: userData.CartItems,
                    bookings: localBookings,
                    familyMembers: sortedFamilyMembers,
                    paymentMethods: activePaymentMethods,
                    stripeCustomerId: userData.stripeCustomerId,
                    defaultPaymentMethodId: userData.defaultPaymentMethodId,
                    referralCode: userData.referralCode,
                    referredBy: userData.referredBy,
                    referralsCount: userData.referralsCount,
                    info: {
                        email: userData.email || '',
                        phone: userData.phone || '',
                        addressOne: userData.addressOne || '',
                        addressTwo: userData.addressTwo || '',
                        city: userData.city || '',
                        state: userData.state || '',
                        zipCode: userData.zipCode || ''
                    }
                };

                setUser(prevData => ({
                    ...prevData,
                    ...mappedUserData
                }));
                setRole(userData.role);
                setCart(userData.CartItems);

                // Check if there are any pets in the familyMembers array
                const hasPets = userData.FamilyMembers.some(member => member.type === 'Dog' || member.type === 'Cat');
                setShowAddPetsModal(!hasPets);
                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch user data:', error.response || error);
                setLoading(false);
            }
        };

        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                const { uid, email, displayName, photoURL: authPhotoURL } = user;
        
                try {
                    const userData = await fetchUserData(uid);
        
                    // Use userData.image if it exists, otherwise fallback to authPhotoURL
                    const photoURL = userData.image ? userData.image : authPhotoURL;
        
                    setUser({ uid, email, displayName, photoURL });
                    setLoggedIn(true);
        
                    const syncSuccess = await syncUserData({ uid, email, displayName, photoURL });
                    if (syncSuccess) {
                        await fetchData(uid);
                    } else {
                        console.log("Skipping fetch data due to sync failure.");
                    }
                } catch (error) {
                    setUser({ uid, email, displayName, authPhotoURL });
                    setLoggedIn(true);
        
                    const syncSuccess = await syncUserData({ uid, email, displayName, authPhotoURL });
                    if (syncSuccess) {
                        await fetchData(uid);
                    } else {
                        console.log("Skipping fetch data due to sync failure.");
                    }
                }
            } else {
                setUser(null);
                setLoggedIn(false);
                setLoading(false); // Ensure loading state is updated when user is not logged in
            }
        });
        

        getServiceDetails();
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        // Ensure cart is an array before performing reduce operations
        if (Array.isArray(cart) && cart.length > 0) {
            // Calculate new total price based on the discountedPrice of each item
            const newTotalPrice = cart.reduce((acc, item) => acc + item.discountedPrice, 0);
            // Calculate the total original price based on the originalPrice of each item
            const totalOriginalPrice = cart.reduce((acc, item) => acc + item.originalPrice, 0);
    
            // Set the new total and total discount based on the calculations
            setCartTotal(newTotalPrice);
            setCartOrigialTotal(totalOriginalPrice);
            setCartTotalDiscount(totalOriginalPrice - newTotalPrice);
        } else {
            // Reset totals if the cart is empty or not an array
            setCartTotal(0);
            setCartOrigialTotal(0);
            setCartTotalDiscount(0);
        }
    }, [cart]); // This effect depends on `cart` and will recalculate totals if `cart` changes    

    // Booking Data
    const [bookingData, setBookingData] = useState({
        bookingType: localStorage.getItem('bookingType') || '',
    });

    const handleAddPets = () => {
        setShowAddPetsModal(false);
        navigate('/profile');
        window.scrollTo(0, 0);
    };

    const handleCloseModal = () => {
        setShowAddPetsModal(false);
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);  // Set the file to state when user selects file
    };

    const handleUpload = async () => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post(`${API_BASE_URL}/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log('Image URL:', response.data.imageUrl);
        } catch (error) {
            console.error('Error uploading image:', error);
        }
    };

    const convertToUserTimeZone = (utcDate, bookingType) => {
        let localDate = moment.utc(utcDate).tz('America/Los_Angeles'); // Convert to local timezone
        return localDate.format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    };
    
    const refreshUserData = async (uid) => {
        setLoading(true);
        try {
            const userData = await fetchUserData(uid);
            console.log('User data fetched from server');

            // Create a user object as a family member
            const userAsFamilyMember = {
                name: userData.username ? userData.username.split(' ')[0] : 'Name',
                type: 'Human',
                image: userData.image || 'https://pawsible-s3.s3.us-west-1.amazonaws.com/user_files/defaultPerson.png', 
            };

            // Optionally sort the remaining family members if necessary, avoiding changing the user's position
            const sortedFamilyMembers = [userAsFamilyMember, ...userData.FamilyMembers.filter(member => member.deletedAt === null).sort((a, b) => {
                if (a.type === 'Human' && b.type !== 'Human') {
                    return -1; // a comes first
                } else if (a.type !== 'Human' && b.type === 'Human') {
                    return 1; // b comes first
                } else {
                    return a.name.localeCompare(b.name); // Sorting by name alphabetically as a secondary criteria
                }
            })];

            // Convert booking times to local time zone
            const localBookings = userData.Bookings.map(booking => ({
                ...booking,
                startTime: convertToUserTimeZone(booking.startTime),
                endTime: convertToUserTimeZone(booking.endTime)
            }));

            const activePaymentMethods = userData.PaymentMethods.filter(method => method.deletedAt === null);
            setPaymentMethods(activePaymentMethods);

            const mappedUserData = {
                id: userData.id,
                uid: userData.uid,
                username: userData.username,
                email: userData.email,
                phone: userData.phone,
                password: userData.password,
                createdAt: userData.createdat,
                updatedAt: userData.updatedat,
                wallet: userData.Wallet,
                userFriends: userData.UserFriends,
                cartItems: userData.CartItems,
                bookings: localBookings,
                familyMembers: sortedFamilyMembers,
                paymentMethods: activePaymentMethods,
                stripeCustomerId: userData.stripeCustomerId,
                defaultPaymentMethodId: userData.defaultPaymentMethodId,
                referralCode: userData.referralCode,
                referredBy: userData.referredBy,
                referralsCount: userData.referralsCount,
                info: {
                    email: userData.email || '',
                    phone: userData.phone || '',
                    addressOne: userData.addressOne || '',
                    addressTwo: userData.addressTwo || '',
                    city: userData.city || '',
                    state: userData.state || '',
                    zipCode: userData.zipCode || ''
                }
            };

            setUser(prevData => ({
                ...prevData,
                ...mappedUserData
              }));
            setCart(userData.CartItems);

            // Check if there are any pets in the familyMembers array
            const hasPets = userData.FamilyMembers.some(member => member.type === 'Dog' || member.type === 'Cat');
            setShowAddPetsModal(!hasPets);
            setLoading(false);
        } catch (error) {
            console.error('Failed to fetch user data:', error.response || error);
            setLoading(false);
        }
    };

    return (
        <StateContext.Provider 
            value={{ 
                role,
                user,
                setUser,
                loggedIn,
                setLoggedIn,
                setLoading,
                serviceDetails,
                cart,
                setCart,
                cartTotal,
                cartOriginalTotal,
                cartTotalDiscount,
                bookingData,
                setBookingData,
                handleFileChange,
                handleUpload,
                refreshUserData,
                paymentMethods, 
                setPaymentMethods,
                setShowAddPetsModal
            }}>
            <Spinner isActive={loading} />

            <div className={`modal ${showAddPetsModal ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: showAddPetsModal ? 'block' : 'none', 'top': '30%'}}>
                <div className="modal-dialog add-pet-modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title add-pet-modal-title">Welcome to Pawsible!</h5>
                            <button type="button" className="close-btn add-pet-close-btn" data-dismiss="modal" aria-label="Close" onClick={handleCloseModal}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body add-pet-modal-body">
                            <p>We are missing your pet profile.</p>
                            <p>Would you like to add one now?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary add-pet-yes-btn" onClick={handleAddPets}>Yes, Add Now</button>
                            <button type="button" className="btn btn-secondary add-pet-no-btn" data-dismiss="modal" onClick={handleCloseModal}>Not Now</button>
                        </div>
                    </div>
                </div>
            </div>

            {children}
        </StateContext.Provider>
    );
};

export default StateProvider;
